import "./App.css";
import { BrowserRouter as Router, Routes, Route, UNSAFE_useScrollRestoration } from "react-router-dom";
import Taxiwala from "./Pages/Taxiwala";
import JerseyKulture from "./Pages/JerseyKulture";
import Quindl from "./Pages/Quindl";
import Marden from "./Pages/Marden";
import Home from "./Pages/Home";
import About from "./Pages/About";
import ScrollToTop from "./Components/ScrolltoTop";

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/taxiwala" Component={Taxiwala} />
          <Route path="/jersey-kulture" Component={JerseyKulture} />
          <Route path="/quindl" Component={Quindl} />
          <Route path="/marden-basketball" Component={Marden} />
          <Route path="/about" Component={About} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
